<template>
  <div class="grid">
    <div class="col-6">
      <div class="card">
        <h5>Importar Base Tower</h5>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="CSV Base Tower" icon="pi pi-upload" class="p-button-success" @click="importarTower" />
          </template>
        </Toolbar>
      </div>
    </div>

    <!-- Card de Relatório Operadora -->
    <div class="col-6">
      <div class="card">
        <h5>Importar Base Operadora</h5>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="CSV Base Operadora" icon="pi pi-upload" class="p-button-success" @click="importarOperadora" />
          </template>
        </Toolbar>
      </div>
    </div>

    <!-- Modal para Relatório Tower -->
    <Dialog v-model:visible="modalTower" :style="{ width: '600px' }" :modal="true" header="Enviar relatório?">
      <p>Deseja enviar o relatório?</p>
      <div class="flex align-items-center justify-content-center">
        <div class="field">
          <FileUpload 
            chooseLabel="Selecionar arquivo csv" 
            ref="arquivoTower" 
            mode="basic" 
            name="arquivoTower"
            @change="onChangeFileUploadTower" />
        </div>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalTower = false" />
        <Button label="Enviar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarTower" />
      </template>
    </Dialog>

    <!-- Modal para Relatório Operadora -->
    <Dialog v-model:visible="modalOperadora" :style="{ width: '600px' }" :modal="true" header="Enviar relatório?">
      <p>Deseja enviar o relatório?</p>
      <div class="flex align-items-center justify-content-center">
        <div class="field">
          <FileUpload 
            chooseLabel="Selecionar arquivo csv" 
            ref="arquivoOperadora" 
            mode="basic" 
            name="arquivoOperadora"
            @change="onChangeFileUploadOperadora" />
        </div>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalOperadora = false" />
        <Button label="Enviar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarOperadora" />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalTower: false,
      modalOperadora: false,
      arquivoTower: null,
      arquivoOperadora: null,
    };
  },
  methods: {
    importarTower() {
      this.modalTower = true;
    },
    importarOperadora() {
      this.modalOperadora = true;
    },
    onChangeFileUploadTower() {
      this.arquivoTower = this.$refs.arquivoTower.files[0];
    },
    onChangeFileUploadOperadora() {
      this.arquivoOperadora = this.$refs.arquivoOperadora.files[0];
    },
    enviarTower() {
      let formData = new FormData();
      formData.append("arquivo", this.arquivoTower);
      
      this.axios
        .post("/base-tim/upload-tower", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Relatório Tower importado com sucesso",
            life: 3000,
          });
          this.modalTower = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao importar relatório Tower",
            life: 3000,
          });
        });
    },
    enviarOperadora() {
      let formData = new FormData();
      formData.append("arquivo", this.arquivoOperadora);
      
      this.axios
        .post("/base-tim/upload-operadora", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Relatório Operadora importado com sucesso",
            life: 3000,
          });
          this.modalOperadora = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao importar relatório Operadora",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.grid {
  margin: 20px 0;
}
</style>